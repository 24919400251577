@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

label {
  @apply font-bold;
}

input::placeholder {
  color: #d7d7e0;
}

// pretty-checkbox-react
.pretty input:checked ~ .state.p-custom-primary label:after,
.pretty.p-toggle .state.p-custom-primary label:after {
  background-color: #58d0bf !important;
}

.pretty .state label:before {
  border-color: #58d0bf;
}
